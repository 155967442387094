import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {LoginResponse} from './login/login.interface'
import {OAKMORE_ATTENTIVE} from '../shared/consts/storage.const'
import {Router} from '@angular/router'
import {firstValueFrom} from 'rxjs'
import {MembersRolePermissionsDefault} from '../shared/consts/members-role.types'

@Injectable({providedIn: 'root'})
export class AuthService {
    private http: HttpClient
    _token: any | null

    user: {user: any, account: any, permissions: MembersRolePermissionsDefault[]}

    constructor(
        http: HttpClient,
        private router: Router
    ) {
        this.http = http
        this.setInitialCredentials()
    }

    setInitialCredentials() {
        const savedCredentials = localStorage.getItem(OAKMORE_ATTENTIVE)

        if (savedCredentials) {
            this.setToken(savedCredentials)
        }
    }

    async login(email: string, password: string): Promise<LoginResponse> {
        try {
            const {access_token} = await firstValueFrom(this.http.post<any>(`auth/login`, {email, password}))
            this.setToken(access_token)
            await this.fetchMe()
            return access_token
        } catch (e) {
            throw e
        }
    }

    async signup(payload: {
        email: string,
        password: string,
        firstname: string,
        surname: string,
    }): Promise<any> {
        try {
            return this.http.post('signup', payload).toPromise()
            // return this.login(email, password)
        } catch (e) {
            throw e
        }
    }

    async logout(): Promise<boolean> {
        this.setToken(null)
        return this.router.navigate(['/auth'], {replaceUrl: true})
    }

    isAuthenticated(): boolean {
        return !!this.token
    }

    async fetchMe() {
        const credentials: any = localStorage.getItem(OAKMORE_ATTENTIVE)
        if (credentials) {
            try {
                this.user = await firstValueFrom(this.http.get<any>(`auth/me`, ))
            } catch (e) {
                this.user = null
                this.setToken(null)
            }
        }

        return this.user
    }

    get token(): any | null {
        return this._token
    }


    setToken(token?: any) {
        this._token = token || null

        if (token) {
            localStorage.setItem(OAKMORE_ATTENTIVE, token)
        } else {
            localStorage.removeItem(OAKMORE_ATTENTIVE)
        }
        return
    }

    isSuperAdmin() {
        return this.user?.user?.isSuperAdmin
    }

    hasPermissions(permission: string) {
        if (!this.isSuperAdmin()) {

            const allPermissions: string[] = this.user.permissions

            return allPermissions?.includes(permission)

        }
        return true
    }

    isAllowedForAdminAndOakmoreMembers() {
        const allowedStrings = ['@oakmorelabs.com', '@admin', 'brenda@ahwus.com', 'geroge@w.com']
        return allowedStrings.some(substring => this.user.account.email.toLowerCase().includes(substring))
    }
}
