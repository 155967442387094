import {Component} from '@angular/core'
import {FooterComponent} from '../shared/components/footer/footer.component'
import {MatCard} from '@angular/material/card'
import {MatButton} from '@angular/material/button'
import {RouterLink, RouterOutlet} from '@angular/router'
import {MatToolbar, MatToolbarRow} from '@angular/material/toolbar'
import {NavbarComponent} from '../shared/components/navbar/navbar.component'

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
    standalone: true,
    imports: [MatToolbar, MatToolbarRow, RouterLink, MatButton, MatCard, RouterOutlet, FooterComponent, NavbarComponent]
})
export class AuthComponent {
}
