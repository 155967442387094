import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandlerFn,
    HttpRequest
} from '@angular/common/http'
import {inject} from '@angular/core'
import {AuthService} from '../../../auth/auth.service'
import {Observable, throwError} from 'rxjs'
import {catchError} from 'rxjs/operators'


export const authInterceptor = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
    const authService = inject(AuthService)
    const token = authService._token

    let newReq = req.clone()

    if (
        token
    ) {
        newReq = req.clone({
            headers: req.headers.set(
                'Authorization',
                'Bearer ' + token
            ),
        })
    }

    return next(newReq).pipe(
        catchError((error) => {
            // Catch "401 Unauthorized" responses
            if (error instanceof HttpErrorResponse && error.status === 401) {
                // Sign out
                authService.logout()

                // Reload the app
                location.reload()
            }

            return throwError(error)
        })
    )
}
