import {Routes} from '@angular/router'
import {AuthComponent} from './auth/auth.component'
import {AuthenticationGuard} from './auth/authentication.guard'

export const appRoutes: Routes = [
    {
        path: 'auth', component: AuthComponent,
        loadChildren: () => import('./auth/auth.routes')
    },
    {path: 'app', loadChildren: () => import('./pages/pages.routes'), canActivate: [AuthenticationGuard]},
    {
        path: 'broker',
        loadComponent: () => import('./unprotected/unprotected-commission/unprotected-commission.component').then(m => m.UnprotectedCommissionComponent)
    },
    {
        path: 'client',
        loadComponent: () => import('./unprotected/unprotected-client/unprotected-client.component').then(m => m.UnprotectedClientComponent)
    },
    {
        path: 'thank-you',
        loadComponent: () => import('./unprotected/thank-you-page/thank-you-page.component').then(m => m.ThankYouPageComponent)
    },
    {path: '', redirectTo: 'app', pathMatch: 'full'},
    {path: '**', redirectTo: 'app'},
]
