import {AuthService} from './auth/auth.service'
import {inject} from '@angular/core'

export function appInitializer(): () => Promise<any> {
    const authService: AuthService = inject(AuthService)
    return async (): Promise<any> => {
        return new Promise(async (resolve) => {
            const me = await authService.fetchMe()
            // moment().locale('cs')
            return resolve([me])
        })
    }
}
