import {Component} from '@angular/core'
import {FOOTER_CONST} from '../../consts/footer.const'
import {MatToolbar} from '@angular/material/toolbar'

@Component({
    selector: 'app-footer',
    standalone: true,
    imports: [
        MatToolbar
    ],
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss'
})
export class FooterComponent {

    protected readonly footer = FOOTER_CONST
}
