
import {EnvTypes} from './env.types'

export const environment: EnvTypes = {
  production: false,
  version: '0.98.7',
  API_URL: 'https://members-back-end-dev.onrender.com/',
  BUILD_TIME: '2024-10-14T15:30:32.748Z',
  FOOTER_TITLE: 'Development'

}
