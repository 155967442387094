
import {EnvTypes} from './env.types'

export const environment: EnvTypes = {
  production: false,
  version: '0.92.1',
  API_URL: 'https://members-back-end-dev.onrender.com/',
  BUILD_TIME: '2024-09-16T16:53:37.349Z',
  FOOTER_TITLE: 'Development'

}
