import {provideHttpClient, withInterceptors} from '@angular/common/http'
import {APP_INITIALIZER, ApplicationConfig} from '@angular/core'
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core'
import {provideAnimations} from '@angular/platform-browser/animations'
import {
    PreloadAllModules,
    provideRouter,
    withInMemoryScrolling,
    withPreloading,
} from '@angular/router'
import {appRoutes} from './app.routes'
import {appInitializer} from './appInitializer'
import {authInterceptor} from './@core/http/interceptors/auth-token.interceptor'
import {errorInterceptor} from './@core/http/interceptors/error-handler.interceptor'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import {apiEndpointInterceptor} from './@core/http/interceptors/api-end-point.interceptor'
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter'


export const appConfig: ApplicationConfig = {
    providers: [
        provideHttpClient(withInterceptors([authInterceptor, errorInterceptor, apiEndpointInterceptor])),
        provideAnimations(),
        provideRouter(
            appRoutes,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({scrollPositionRestoration: 'enabled'})
        ),
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializer,
            multi: true,
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                floatLabel: 'always',
                appearance: 'outline',
                subscriptSizing: 'dynamic'
            }
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'en-US',
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'MM-DD-YYYY',
                },
                display: {
                    dateInput: 'MM-DD-YYYY',
                    monthYearLabel: 'LLL yyyy',
                    dateA11yLabel: 'DD',
                    monthYearA11yLabel: 'LLLL yyyy',
                },
            },
        },
        {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    ]
}
